/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-26",
    versionChannel: "nightly",
    buildDate: "2023-09-26T00:20:19.266Z",
    commitHash: "7e4e4d4c5b1f55614b98b33b7cc6f70567c52bab",
};
